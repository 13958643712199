$('a').on('click', function(e) {
    if (/#/.test(this.href)) {
        //check if url is the same

        //clicked link
        var clicked_location = this.href.split('#')[0];
        var target = '#' + this.href.split('#')[1];

        //only if target not starts with 'tab' --> woocommerce tabs
        if(target.substr(1,3) !== 'tab') {
            //current location
            var current_location = window.location.href.split('#')[0];

            e.preventDefault();

            if($(window).width() <= 992) {
                var header_height = 0;
            } else {
                var header_height = $('.header').height();
            }

            if (current_location == clicked_location) {
                //Scroll, is the same url
                var $target = $(target);
                e.preventDefault();

                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top - header_height
                }, 900, 'swing', function () {
                    // window.location.hash = target;
                });
            } else {
                location.replace(this.href);
            }
        }
    } 
});


//Mobile Nav
$('.header__menu').on('click', function() {
    $(this).toggleClass('header__menu--open');
    $('.header__nav').toggleClass('header__nav--open');
});

$('.nav__item').on('click', function() {
    if($(window).width() <= 992) {
        $('.header__menu').removeClass('header__menu--open');
        $('.header__nav').removeClass('header__nav--open');
    }
});

$('.menu__item-subopener').on('click', function() {
    var opener = $(this);
    var submenu = $(this).next();

    $('.menu__item-subopener').not(opener).each(function() {
        $(this).removeClass('menu__item-subopener--open');
    });

    $('.menu__submenu').not(submenu).each(function() {
        $(this).removeClass('menu__submenu--open');
    });

    opener.toggleClass('menu__item-subopener--open');
    submenu.toggleClass('menu__submenu--open');
});

//Open Submenu

var timeout;
if($(window).width() > 992) {
    $('.menu__item-haschild').hover(
        function() {
            clearTimeout(timeout);
            $(this).find('.menu__submenu').addClass( 'menu__submenu--open' );
        }, function() {
            var that = $(this);
            
            timeout = setTimeout(function(){
                that.find('.menu__submenu').removeClass( 'menu__submenu--open' );
            }, 800);
        }
    );
}
