var Towa = jQuery.extend({}, Towa || {});

//custom build only for gallery. if you need more, use full plugin
Towa.Modules.slider = (function ($) {
    "use strict";

    var _ = {},
        self = {};

    _.sliderFrontpage = function ($obj) {
        if (!$obj.length) {
            return;
        }

        $obj.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHight: true,
            nextArrow: '<i class="custom-next slider__arrow slider__arrow--right"></i>',
            prevArrow: '<i class="custom-prev slider__arrow slider__arrow--left"></i>',
            loop: true,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnFocus: true,
            dots: false,
            speed: 1000,
            cssEase: 'ease-out',
        });
    };

    _.sliderFullscreen = function ($obj) {
        if (!$obj.length) {
            return;
        }

        $obj.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHight: true,
            nextArrow: '<i class="custom-next slider__arrow slider__arrow--right"></i>',
            prevArrow: '<i class="custom-prev slider__arrow slider__arrow--left"></i>',
            dots: false,
            speed: 500,
            cssEase: 'ease-out',
        });
    };

    _.sliderContent = function ($obj) {
        if (!$obj.length) {
            return;
        }

        $obj.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHight: false,
            nextArrow: '<i class="custom-next slider__arrow slider__arrow--right"></i>',
            prevArrow: '<i class="custom-prev slider__arrow slider__arrow--left"></i>',
            loop: true,
        });
    };

    _.sliderCompanies = function ($obj) {
        if (!$obj.length) {
            return;
        }

        $obj.slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHight: false,
            // nextArrow: '<i class="custom-next slider__arrow slider__arrow--right"></i>',
            // prevArrow: '<i class="custom-prev slider__arrow slider__arrow--left"></i>',
            prevArrow: $('#prevArrow'),
            nextArrow: $('#nextArrow'),
            loop: true,
            dots: true,

            responsive: [
                {
                  breakpoint: 993,
                  settings: {
                    slidesToShow: 2,
                  }
                },
                {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                    }
                  },
              ]
        });
    };

    self.init = function () {
        _.sliderFrontpage($('.slider--frontpage'));
        _.sliderFullscreen($('.slider--fullscreen'));
        _.sliderContent($('.slider--content'));
        _.sliderCompanies($('.slider--companies'));
    };

    self.init();

    return self;
}(jQuery));

